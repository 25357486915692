import React, { Component } from 'react';

export class SignUpForm extends Component {
    render() {
        return (
            <div className="col-md-8">
                <div className="card">
                    <div className="card-header">Register</div>
                    <div className="card-body">
                        <form>
                            <div className="form-group row">
                                <label for="full_name" class="col-md-4 col-form-label text-md-right">Full Name</label>
                                <div className="col-md-6">
                                    <input type="text" id="full_name" class="form-control" name="full-name" />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label for="email_address" class="col-md-4 col-form-label text-md-right">E-Mail Address</label>
                                <div className="col-md-6">
                                    <input type="text" id="email_address" class="form-control" name="email-address" />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label for="user_name" class="col-md-4 col-form-label text-md-right">User Name</label>
                                <div className="col-md-6">
                                    <input type="text" id="user_name" class="form-control" name="username" />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label for="phone_number" class="col-md-4 col-form-label text-md-right">Phone Number</label>
                                <div className="col-md-6">
                                    <input type="text" id="phone_number" class="form-control" />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label for="present_address" class="col-md-4 col-form-label text-md-right">Present Address</label>
                                <div className="col-md-6">
                                    <input type="text" id="present_address" class="form-control" />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label for="permanent_address" class="col-md-4 col-form-label text-md-right">Permanent Address</label>
                                <div className="col-md-6">
                                    <input type="text" id="permanent_address" class="form-control" name="permanent-address" />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label for="nid_number" class="col-md-4 col-form-label text-md-right"><abbr
                                            title="National Id Card">NID</abbr> Number</label>
                                <div className="col-md-6">
                                    <input type="text" id="nid_number" class="form-control" name="nid-number" />
                                </div>
                            </div>

                            <div className="col-md-6 offset-md-4">
                                <button type="submit" class="btn btn-primary">
                                Register
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
export default SignUpForm;
