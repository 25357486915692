import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { fetchData } from "./store/fetchData";
import {connect} from 'react-redux';

import Home from './pages/Home';
import About from './pages/About';
import Model from './pages/Model';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import NotFound from './pages/NotFound';

import Header from './components/Header';
import Footer from './components/Footer';

class App extends Component {
  componentDidMount() {
      this.props.dispatch(fetchData());
  }

  render(){
    return (
        <Router>
          <div className="App">
          <Header />
          <Switch>
                <Route exact path='/' component={Home} />
                <Route path='/about' component={About} />
                <Route path='/signin' component={SignIn} />
                <Route path='/signup' component={SignUp} />
                <Route path='/404' component={NotFound} />
                <Route 
                  exact
                  name="model" 
                  path='/:permalink' 
                  render={(props) => (<Model {...props} />)} 
                />
                <Route component={NotFound} />
            </Switch>
            </div>
            <Footer />
      </Router>
    );
  }
}

const mapStateToProps = state => ({
  content: state.data.content,
  loading: state.data.loading,
  error: state.data.error
});

export default connect(mapStateToProps, null)(App);
