import React, { Component } from 'react';
import Single from '../components/Single';
import {Helmet} from "react-helmet";
import {connect} from 'react-redux';

export class Model extends Component {

    constructor(props) {
        super(props);

        this.state = {
            content: props.content
        };
    }

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.props.match.params.permalink}</title>
                </Helmet>

                <div className="container">
                  <Single permalink={this.props.match.params.permalink}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        content: state.data
    }
}

export default connect(mapStateToProps, null)(Model);
