import React, { Component } from 'react';
import SignUpForm from '../components/SignUpForm';
import {Helmet} from "react-helmet";


export class Signup extends Component {
    render() {
        return (
            <div className="container">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Sign UP</title>
                </Helmet>

                <div className="row justify-content-center">
                  <SignUpForm />
                </div>
            </div>
        );
    }
}
export default Signup;
