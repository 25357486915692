function getData() {
  return fetch("https://origin.nuvision.pornstarplatinum.com/misc/crushgirls/api/content.php?query=")
    .then(handleErrors)
    .then(res => res.json());
}

export function fetchData() {
  return dispatch => {
    dispatch(fetchDataBegin());
    return getData()
      .then(json => {
        dispatch(fetchDataSuccess(json.data));
        return json.data;
      })
      .catch(error =>
        dispatch(fetchDataFailure(error))
      );
  };
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export const FETCH_DATA_BEGIN = "FETCH_DATA_BEGIN";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";

export const fetchDataBegin = () => ({
  type: FETCH_DATA_BEGIN
});

export const fetchDataSuccess = scenes => ({
  type: FETCH_DATA_SUCCESS,
  payload: { scenes }
});

export const fetchDataFailure = error => ({
  type: FETCH_DATA_FAILURE,
  payload: { error }
});
