import React, { Component } from 'react';
import parse from 'html-react-parser';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";


export class Trailer extends Component {
    render() {
        return (
            <div className="trailer row">
                <h1>{this.props.title}</h1>
                <video autoplay controls>
                  <source src={this.props.trailer} type="video/mp4" />
                </video>
                <p>{this.props.description}</p>
            </div>
        );
    }
}
export default Trailer;
