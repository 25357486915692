import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { lazyload } from 'react-lazyload';

export class Thumbnails extends Component {
    render() {
        return (
            <div className="thumbs col-md-3">
                <Link to={ this.props.permalink } >
                <img className="img-responsive" src={this.props.thumbnail} />{this.props.title}
                </Link>
            </div>
        );
    }
}
export default Thumbnails;
