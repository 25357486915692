import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import Contents from '../components/Contents';

export class NotFound extends Component {
    render() {
        return (
            <div>

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>NOT FOUND</title>
                </Helmet>

                <div className="container text-center pt-5">
                  <h1> 404 </h1>
                  <h5>Not Found</h5>
                </div>
            </div>
        );
    }
}
export default NotFound;
