import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

export class Header extends Component {
    render() {
        return (
          <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <Link to="/" className="navbar-brand"><img src="https://z8i9a8v9.ssl.hwcdn.net/static_cg_tour_assets/crushgirls/images/logo.png" /></Link> 
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link to="/" className="nav-link" activeClassName="active">Home</Link> 
                  </li>
                  <li className="nav-item">
                    <Link to="/about" className="nav-link" activeClassName="active">About</Link> 
                  </li>
                  <li className="nav-item">
                    <Link to="/signin" className="nav-link" activeClassName="active">Sign in</Link> 
                  </li>
                  <li className="nav-item">
                    <Link to="/signup" className="nav-link" activeClassName="active">Sign Up</Link> 
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        );
    }
}

export default Header;
