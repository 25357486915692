import React, { Component } from 'react';
import SignInForm from '../components/SignInForm';
import {Helmet} from "react-helmet";

export class SignIn extends Component {
    render() {
        return (
            <div className="container">

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Sign In</title>
                </Helmet>

                <div className="row justify-content-center">
                  <SignInForm />
                </div>
            </div>
        );
    }
}
export default SignIn;
