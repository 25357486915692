import React, { Component } from 'react';
import ThumbnailGrid from './ThumbnailGrid';

export class Contents extends Component {
    render() {
        return (
            <div>
                <ThumbnailGrid />
            </div>
        );
    }
}

export default Contents;
