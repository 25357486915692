import React, { Component } from 'react';

export class Footer extends Component {
    render() {
        return (
            <div  className="page-footer font-small blue pt-4">
            <div className="footer-copyright text-center py-3">© 2020 Copyright:
                <a href="https://Transfans.com/"> {process.env.REACT_APP_SECRET_NAME}</a>
              </div>
              </div>
        );
    }
}

export default Footer;
