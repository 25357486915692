import React, { Component } from 'react';
import Thumbnails from './Thumbnails';
import {connect} from 'react-redux';

export class ThumbnailGrid extends Component {
    render() {
        const { content, loading } = this.props;

        if (loading) {
          return <div>Loading...</div>;
        }

        return (
            <div className="row contents">
                { 
                    Object.keys(content).map((item, i) => (
                        Object.values(content[item]).map((scene, key) =>
                            <Thumbnails 
                            title={scene.title}
                            thumbnail={scene.thumbnail}
                            permalink={scene.permalink}
                            trailer={scene.trailer}
                           />
                        )
                    ))
                }  
            </div>
        );
    }
}

const mapStateToProps = state => ({
  content: state.data.content,
  loading: state.data.loading,
  error: state.data.error
});

export default connect(mapStateToProps, null)(ThumbnailGrid);
