import React, { Component } from 'react';
import { withRouter, Redirect } from "react-router";
import {useSelector, connect} from 'react-redux';
import Trailer from './Trailer';

export class Single extends Component {
    render() {
        const { content } = this.props;
        return (
            <div className="contents singles">
                { 
                    Object.keys(content).map((item, i) => (
                        Object.values(content[item]).map((scene, key) => {
                            return this.props.permalink == scene.permalink && (<Trailer 
                                trailer={scene.trailer} 
                                title={scene.title} 
                                description={scene.description}
                            />)
                        }
                        )
                    ))
                }  
            </div>
        );
    }
}

const mapStateToProps = state => ({
  content: state.data.content,
  loading: state.data.loading,
  error: state.data.error
});

export default connect(mapStateToProps, null)(Single);
