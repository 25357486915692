import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import Contents from '../components/Contents';

export class Home extends Component {
    render() {
        return (
            <div>

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>TransFans</title>
                    <link rel="canonical" href="http://mysite.com/example" />
                </Helmet>

                <div className="container">
                  <Contents />
                </div>
            </div>
        );
    }
}
export default Home;
